<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="userAddSidebarActive"
      @change="(val) => $emit('update:user-add-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Nuevo Usuario
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="onReset"
          >
            <!-- Nombre -->
            <validation-provider
              v-slot="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  autocomplete="new-password"
                  placeholder="Ingresa el nombre"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Correo -->
            <validation-provider
              v-slot="{ errors }"
              name="correo"
              rules="required|email"
            >
              <b-form-group
                label="Correo:"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.mail"
                  autocomplete="new-password"
                  placeholder="Ingresa el correo"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Rol -->
            <validation-provider
              v-slot="{ errors }"
              name="rol"
              rules="required"
            >
              <b-form-group
                label="Rol:"
                label-for="select-rol"
              >
                <v-select
                  v-model="form.role"
                  autocomplete="new-password"
                  :options="roles"
                  :reduce="role => role.value"
                  :clearable="true"
                  placeholder="Selecciona un rol"
                  input-id="select-role"
                  required
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron roles
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="contraseña"
              rules="required|password"
            >
              <b-form-group>
                <label for="input-pass">Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-pass"
                    v-model="form.password"
                    autocomplete="new-password"
                    :type="passwordType"
                    placeholder="Ingresa la contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Validar Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="confirmar contraseña"
              rules="required|confirmed:contraseña"
            >
              <b-form-group>
                <label for="input-confirmPass">Confirmar Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-confirmPass"
                    v-model="form.passwordConfirm"
                    autocomplete="new-password"
                    :type="passwordConfirmType"
                    placeholder="Confirma la contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordConfirmToggleIcon"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Crear
            </b-button>
            <b-button
              style="margin-left: 5px;"
              type="reset"
              variant="danger"
            >
              Limpiar
            </b-button>
          </b-form>
        </validation-observer>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  model: {
    prop: 'userAddSidebarActive',
    event: 'update:user-add-sidebar-active',
  },
  props: {
    userAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,

      form: {
        name: '',
        mail: '',
        role: '',
        password: '',
        passwordConfirm: '',
      },

      show: true,

      passwordType: 'password',
      passwordConfirmType: 'password',
      passwordToggleIcon: 'EyeIcon',
      passwordConfirmToggleIcon: 'EyeIcon',

      roles: [
        { value: 'admin', label: 'Administrador' },
        { value: 'vendor', label: 'Vendedor' },
      ],
    }
  },
  methods: {
    togglePassword() {
      this.passwordType = (this.passwordType === 'password') ? 'text' : 'password'
      this.passwordToggleIcon = (this.passwordType === 'password') ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordConfirm() {
      this.passwordConfirmType = this.passwordConfirmType === 'password' ? 'text' : 'password'
      this.passwordConfirmToggleIcon = this.passwordConfirmType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    onSubmit(event) {
      event.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios
            .post('system/register', this.form)
            .then(response => {
              this.makeToast('success', 'Usuario agregado', `Se ha creado el usuario ${response.data.data.name}.`)
              this.clearForm()
              this.$emit('update:user-add-sidebar-active', false)
              this.$emit('refreshData')
            })
            .catch(error => {
              this.showErrors(error)
            })
        } else {
          this.makeToast('danger', 'Error', 'No es posible crear el usuario.')
        }
      })
    },

    onReset(event) {
      event.preventDefault()
      this.clearForm()
    },

    clearForm() {
      // Reset our form values
      this.form.name = ''
      this.form.mail = ''
      this.form.role = ''
      this.form.password = ''
      this.form.passwordConfirm = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
