<template>
  <div>
    <users-add
      :user-add-sidebar-active.sync="userAddSidebarActive"
      @refreshData="getUsers"
    />

    <users-edit
      v-if="updateShow"
      :user-id="Number(userId)"
      :user-edit-sidebar-active.sync="userEditSidebarActive"
      @refreshData="getUsers"
    />

    <users-password
      v-if="passwordShow"
      :user-id="Number(userId)"
      :user-password-sidebar-active.sync="userPasswordSidebarActive"
      @refreshData="getUsers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="userData().role === 'admin' || userData().role === 'vendor'"
              variant="primary"
              @click="userAddSidebarActive = true"
            >
              Nuevo
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar (nombre o correo)"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        small
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :busy="tableIsBusy"
        :items="usersData"
        :fields="usersFields"
        :per-page="perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head(id)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(status)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn('active')"
          >{{ data.label }}
          </span>
        </template>

        <template #head(name)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>
        <template #head(email)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>
        <template #head(role)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>
        <template #head(created_at)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>
        <template #head(updated_at)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="userData().role === 'admin'"
          #cell(actions)="data"
        >
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="(data.item.status === 'Y')"
                @click="updateUser(data.item.id)"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="(data.item.status === 'Y')"
                @click="changeStatus(data.item.id, 'Y')"
              >
                <feather-icon icon="UserMinusIcon" />
                <span class="align-middle ml-50">Inactivar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="changeStatus(data.item.id, 'N')"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="align-middle ml-50">Activar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="(data.item.status === 'Y')"
                @click="updatePassword(data.item.id)"
              >
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Contraseña</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold text-info"
          >
            #{{ data.item.id }}
          </b-link>
        </template>
        <!-- Column: estado -->
        <template #cell(status)="data">
          <b-badge
            :variant="(data.item.status === 'Y') ? 'light-success' : 'light-danger'"
          >
            {{ (data.item.status === 'Y') ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>
        <!-- Column: role -->
        <template #cell(role)="data">
          {{ (data.item.role === 'admin') ? 'Administrador' : 'Vendedor' }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination info -->
          <b-col
            v-if="usersMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ usersMeta.from }} al {{ usersMeta.to }} de {{ usersMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="usersMeta"
              v-model="currentPage"
              :total-rows="usersMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'
import UsersAdd from './UsersAdd.vue'
import UsersEdit from './UsersEdit.vue'
import UsersPassword from './UsersPassword.vue'

export default {
  components: {
    vSelect,
    UsersAdd,
    UsersEdit,
    UsersPassword,
  },

  data() {
    return {
      userAddSidebarActive: false,
      userEditSidebarActive: false,
      userPasswordSidebarActive: false,

      searchQuery: '',

      sortField: 'id',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,

      usersFields: [
        { key: 'actions', label: '' },
        { key: 'id', label: 'Id' },
        { key: 'status', label: 'Estado' },
        { key: 'name', label: 'Nombre' },
        { key: 'email', label: 'Correo' },
        { key: 'role', label: 'Rol' },
        { key: 'created_at', label: 'Creado' },
        { key: 'updated_at', label: 'Actualizado' },
      ],
      usersData: null,
      usersMeta: null,

      updateShow: false,
      passwordShow: false,
      userId: 0,
      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getUsers()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getUsers()
    },
    searchQuery() {
      // esperar 2 segundos para no saturar el servidor
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.tableIsBusy = true
        this.getUsers()
      }, 1000)
    },
  },

  created() {
    this.getUsers()
  },

  methods: {
    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    getUsers() {
      if (this.perPage === 'Todos') {
        this.perPage = this.usersMeta.total
      }

      axios
        .get(`system/users?perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          this.usersMeta = response.data.meta
          this.usersData = response.data.data
        })
        .catch(error => {
          this.tableIsBusy = false
          this.showErrors(error)
        })
    },

    updateUser(id) {
      this.updateShow = true
      this.userEditSidebarActive = true
      this.userId = id
    },

    updatePassword(id) {
      this.passwordShow = true
      this.userPasswordSidebarActive = true
      this.userId = id
    },

    changeStatus(id, status) {
      this.$bvModal
        .msgBoxConfirm('¿Deseas cambiar el estado actual del usuario?', {
          title: (status === 'N') ? 'Activar usuario' : 'Inactivar usuario',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`system/users/${id}/status`)
              .then(response => {
                const msg = (status === 'N') ? 'Activado' : 'Inactivado'
                this.makeToast('success', msg, `Se ha actualizado el estado del usuario ${response.data.data.name}.`)
                this.getUsers()
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getUsers()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
