<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="userEditSidebarActive"
      @change="(val) => $emit('update:user-edit-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Editar Usuario
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="clearForm"
          >
            <!-- Nombre -->
            <validation-provider
              v-slot="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Ingresa el nombre"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Correo -->
            <validation-provider
              v-slot="{ errors }"
              name="correo"
              rules="required|email"
            >
              <b-form-group
                label="Correo:"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.mail"
                  autocomplete="new-password"
                  placeholder="Ingresa el correo"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Rol -->
            <validation-provider
              v-slot="{ errors }"
              name="rol"
              rules="required"
            >
              <b-form-group
                label="Rol:"
                label-for="select-rol"
              >
                <v-select
                  v-model="form.role"
                  autocomplete="new-password"
                  :options="roles"
                  :reduce="role => role.value"
                  :clearable="true"
                  placeholder="Selecciona un rol"
                  input-id="select-role"
                  required
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron roles
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Actualizar
            </b-button>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  model: {
    prop: 'userEditSidebarActive',
    event: 'update:user-edit-sidebar-active',
  },

  props: {
    userEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      required,

      form: {
        name: '',
        mail: '',
        role: '',
      },
      show: true,

      roles: [
        { value: 'admin', label: 'Administrador' },
        { value: 'vendor', label: 'Vendedor' },
      ],
    }
  },

  watch: {
    userEditSidebarActive(val) {
      if (val) {
        this.getUser()
      }
    },
  },

  created() {
    this.getUser()
  },

  methods: {
    getUser() {
      axios
        .get(`system/users/${this.userId}`)
        .then(response => {
          this.form.name = response.data.data.name
          this.form.mail = response.data.data.email
          this.form.role = response.data.data.role
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSubmit(event) {
      event.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios
            .put(`system/users/${this.userId}`, this.form)
            .then(response => {
              this.makeToast('success', 'Usuario actualizado', `Se ha actualizado el usuario ${response.data.data.name}.`)
              this.clearForm()
              this.$emit('update:user-edit-sidebar-active', false)
              this.$emit('refreshData')
            })
            .catch(error => {
              this.showErrors(error)
            })
        } else {
          this.makeToast('danger', 'Error', 'No es posible actualizar el usuario.')
        }
      })
    },

    clearForm() {
      this.form.name = ''
      this.form.mail = ''
      this.form.role = ''

      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
