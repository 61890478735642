var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.userEditSidebarActive},on:{"change":function (val) { return _vm.$emit('update:user-edit-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Editar Usuario ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit,"reset":_vm.clearForm}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Ingresa el nombre","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo:","label-for":"input-email"}},[_c('b-form-input',{attrs:{"id":"input-email","autocomplete":"new-password","placeholder":"Ingresa el correo","required":""},model:{value:(_vm.form.mail),callback:function ($$v) {_vm.$set(_vm.form, "mail", $$v)},expression:"form.mail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rol:","label-for":"select-rol"}},[_c('v-select',{attrs:{"autocomplete":"new-password","options":_vm.roles,"reduce":function (role) { return role.value; },"clearable":true,"placeholder":"Selecciona un rol","input-id":"select-role","required":""},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron roles ")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Actualizar ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }