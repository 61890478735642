<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="userPasswordSidebarActive"
      @change="(val) => $emit('update:user-password-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Actualizar Contraseña
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="clearForm"
          >
            <!-- Datos del usuario, solo texto, no inputs -->
            <div class="mb-2">
              <h4>{{ name }}</h4>
              <p class="mb-0">
                {{ mail }}
              </p>
              <p class="mb-0">
                {{ role }}
              </p>
            </div>

            <!-- Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="contraseña"
              rules="required|password"
            >
              <b-form-group>
                <label for="input-pass">Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-pass"
                    v-model="form.password"
                    autocomplete="new-password"
                    :type="passwordType"
                    placeholder="Ingresa la contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Validar Contraseña -->
            <validation-provider
              v-slot="{ errors }"
              name="confirmar contraseña"
              rules="required|confirmed:contraseña"
            >
              <b-form-group>
                <label for="input-confirmPass">Confirmar Contraseña</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="input-confirmPass"
                    v-model="form.passwordConfirm"
                    autocomplete="new-password"
                    :type="passwordConfirmType"
                    placeholder="Confirma la contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordConfirmToggleIcon"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Actualizar
            </b-button>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  model: {
    prop: 'userPasswordSidebarActive',
    event: 'update:user-password-sidebar-active',
  },

  props: {
    userPasswordSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      required,

      name: '',
      mail: '',
      role: '',

      form: {
        password: '',
        passwordConfirm: '',
      },
      show: true,

      passwordType: 'password',
      passwordConfirmType: 'password',
      passwordToggleIcon: 'EyeIcon',
      passwordConfirmToggleIcon: 'EyeIcon',
    }
  },

  watch: {
    userPasswordSidebarActive(val) {
      if (val) {
        this.getUser()
      }
    },
  },

  created() {
    this.getUser()
  },

  methods: {
    togglePassword() {
      this.passwordType = (this.passwordType === 'password') ? 'text' : 'password'
      this.passwordToggleIcon = (this.passwordType === 'password') ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordConfirm() {
      this.passwordConfirmType = this.passwordConfirmType === 'password' ? 'text' : 'password'
      this.passwordConfirmToggleIcon = this.passwordConfirmType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    getUser() {
      axios
        .get(`system/users/${this.userId}`)
        .then(response => {
          this.name = response.data.data.name
          this.mail = response.data.data.email
          this.role = (response.data.data.role === 'admin') ? 'Administrador' : 'Vendedor'
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSubmit(event) {
      event.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios
            .put(`system/users/${this.userId}/password`, this.form)
            .then(response => {
              this.makeToast('success', 'Contraseña actualizada', `Se ha actualizado la contraseña del usuario ${response.data.data.name}.`)
              this.clearForm()
              this.$emit('update:user-password-sidebar-active', false)
              this.$emit('refreshData')
            })
            .catch(error => {
              this.showErrors(error)
            })
        } else {
          this.makeToast('danger', 'Error', 'No es posible actualizar la contraseña del usuario.')
        }
      })
    },

    clearForm() {
      this.form.password = ''
      this.form.passwordConfirm = ''

      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
